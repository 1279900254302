import request from '../utils/request'
// const base_url = 'http://127.0.0.1:8000/api/v1/'
const base_url = 'https://dz.gzsdzth.com/api/v1/'

export const getConfig = (keyword) => {
  return request({
    url: base_url + `user/config?keyword=${keyword}`,
    method: 'get'
  })
}

export const postConfig = (keyword, data) => {
  return request({
    url: base_url + `user/config?keyword=${keyword}`,
    method: 'post',
    data
  })
}

export const getUsers = (page = 1) => {
  return request({
    url: base_url + 'user/users?page=' + page,
    method: 'get'
  })
}

export const getCabinet = (page = 1) => {
  return request({
    url: base_url + 'user/cabinetManage?page=' + page,
    method: 'get'
  })
}

export const getReserve = (page = 1) => {
  return request({
    url: base_url + 'user/reserve?page=' + page,
    method: 'get'
  })
}

export const login = (data) => {
  return request({
    url: base_url + 'admin/login',
    method: 'post',
    data
  })
}

export const addCabinet = (data) => {
  return request({
    url: base_url + 'cabinet/info',
    method: 'post',
    data
  })
}

export const getNextArticle = (page, kw) => {
  return request({
    url: base_url + 'usr/article?page=' + page + '&kw=' + kw,
    method: 'get'
  })
}

export const getReviewArticle = () => {
  return request({
    url: base_url + 'usr/review',
    method: 'get'
  })
}

export const reviewInfo = (query) => {
  return request({
    url: base_url + 'usr/review',
    method: 'post',
    data: query
  })
}

// export const editArticle = (query)=> {
//     return request({
//         url: base_url + 'usr/article',
//         method: 'patch',
//         data: query
//     })
// }

export const delArticle = (query) => {
  return request({
    url: base_url + 'usr/article',
    method: 'delete',
    params: { id: query }
  })
}

export const singleArticle = (query) => {
  return request({
    url: base_url + 'usr/single',
    method: 'get',
    params: { id: query }
  })
}

export const chartData = () => {
  return request({
    url: base_url + 'usr/chart',
    method: 'get'
  })
}

export const userData = () => {
  return request({
    url: base_url + 'usr/account',
    method: 'get'
  })
}

export const createUser = (query) => {
  return request({
    url: base_url + 'usr/account',
    method: 'post',
    data: query
  })
}

export const pwReset = (query) => {
  return request({
    url: base_url + 'usr/reset',
    method: 'post',
    data: query
  })
}

export const delUser = (query) => {
  return request({
    url: base_url + 'usr/reset',
    method: 'delete',
    params: { id: query }
  })
}

export const searchData = (query) => {
  return request({
    url: base_url + 'usr/search',
    method: 'post',
    data: query
  })
}

export const getFloatImg = () => {
  return request({
    url: base_url + 'usr/float',
    method: 'get'
  })
}

export const submitFloatImg = (query) => {
  return request({
    url: base_url + 'usr/float',
    method: 'post',
    data: query
  })
}

export const worksList = (page, period_id, type) => {
  return request({
    url:
      base_url + `admin/works?page=${page}&period_id=${period_id}&type=${type}`,
    method: 'get'
  })
}

export const periodList = () => {
  return request({
    url: base_url + 'admin/period'
  })
}

export const createRank = (data) => {
  return request({
    url: base_url + 'admin/rank',
    data,
    method: 'post'
  })
}

export const resultList = (page, period_id, type) => {
  return request({
    url:
      base_url +
      `admin/result?page=${page}&period_id=${period_id}&type=${type}`,
    method: 'get'
  })
}

export const delWork = (id) => {
  return request({
    url: base_url + `admin/del/${id}`,
    method: 'delete'
  })
}

export const zipFile = (id) => {
  return request({
    url: base_url + `admin/zip?period_id=${id}`,
    method: 'get'
  })
}

export const uploadWork = (data) => {
  return request({
    url: base_url + 'admin/adminWorks',
    method: 'post',
    data
  })
}

export const getPeriod = () => {
  return request({
    url: base_url + 'admin/adminPeriod'
  })
}

export const createNewPeriod = (data) => {
  return request({
    url: base_url + 'admin/addPeriod',
    method: 'post',
    data
  })
}

export const startNewPeriod = (pId, nId) => {
  return request({
    url: base_url + 'admin/startPeriod',
    method: 'post',
    data: {
      prevId: pId,
      nextId: nId
    }
  })
}

export const editCurrentPeriod = (id, start_date, end_date) => {
  return request({
    url: base_url + 'admin/editPeriod',
    method: 'post',
    data: {
      id,
      start_date,
      end_date
    }
  })
}
