import axios from 'axios'
import router from '../router/index'

const service = axios.create({
  // process.env.NODE_ENV === 'development' 来判断是否开发环境
  // easy-mock服务挂了，暂时不使用了
  // baseURL: 'https://www.easy-mock.com/mock/592501a391470c0ac1fab128',
  timeout: 5000
})

service.interceptors.request.use(
  (config) => {
    if (localStorage.getItem('token')) {
      //token字段是要和后端协商好的
      config.headers.common['Authorization'] = localStorage.getItem('token')
      // debugger
    }
    return config
  },
  (error) => {
    console.log(error)
    return Promise.reject()
  }
)

service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response.data
    } else {
      Promise.reject()
    }
  },
  (error) => {
    if (error.response.status === 401) {
      router.push({
        path: '/login'
      })
    }
    return Promise.reject()
  }
)

export default service
